import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from "@angular/core";
import { StreamTypes } from "@auvious/rtc";
import { merge, Subscription } from "rxjs";

import { FormGroup } from "@angular/forms";
import { MediaDevices } from "@auvious/media-tools";
import { localStore } from "@auvious/utils";
import { KEY_USER_DISPLAY_NAME } from "../../../app/app.enums";
import { fadeInOut, slideIn, slideInOut } from "../../core-ui.animations";
import {
  ConversationTypeEnum,
  NotificationSoundEnum,
  StreamTrackKindEnum,
  UserRoleEnum,
} from "../../core-ui.enums";
import { CustomerParam, IDeviceSetupOptions, ITile } from "../../models";
import { IUserDetails } from "../../models/IUser";
import {
  ActivityIndicatorService,
  ConferenceStore,
  debugError,
  DeviceService,
  KeyboardService,
  KeyCodes,
  LocalMediaService,
  MediaEffectsService,
  MediaRulesService,
  StreamState,
  UserService,
  VoiceDetectionService,
} from "../../services";
import { AppConfigService } from "../../services/app.config.service";
import { NotificationService } from "../../services/notification.service";
import { RoomComponent } from "../room/room.component";
import { TileComponent } from "../tile/tile.component";
import { WindowService } from "../../services/window.service";
import { markFormFields } from "../../../app/app.utils";

@Component({
  selector: "app-device-setup",
  templateUrl: "./device-setup.component.html",
  styleUrls: ["./device-setup.component.scss"],
  animations: [slideIn, slideInOut, fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceSetupComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("volumeRef") volumeElem: ElementRef<HTMLDivElement>;
  @ViewChild("tile") tileRef: TileComponent;

  @Input() conversationType: ConversationTypeEnum;

  @Output() canceled = new EventEmitter<void>();
  @Output() accepted = new EventEmitter<IDeviceSetupOptions>();

  @ViewChild("effectsMenu", { read: ElementRef })
  effectsMenuRef: ElementRef<HTMLElement>;

  @ViewChild("effectsMenuButton", { read: ElementRef })
  effectsMenuButtonRef: ElementRef<HTMLElement>;

  isJoining: boolean;
  isAgent: boolean;
  isCustomer: boolean;
  devicesOpen = false;
  isTileReady = false;
  skipSetup = false;
  isGuest: boolean;
  isApplyingFilter = false;
  isSwitching = false;
  isEffectsOpen = false;
  isTogglingCam = false;
  isRequestingName = true;
  participantName: string;

  micOn = true;
  camOn = true;
  stream: StreamState;

  subscription: Subscription;

  constructor(
    private deviceService: DeviceService,
    private mediaService: LocalMediaService,
    private voiceDetectionService: VoiceDetectionService,
    private notification: NotificationService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private mediaFilter: MediaEffectsService,
    private activity: ActivityIndicatorService,
    private mediaRules: MediaRulesService,
    private store: ConferenceStore,
    private winService: WindowService,
    private keyboard: KeyboardService,
    @Optional() private room: RoomComponent,
    config: AppConfigService
  ) {
    this.subscription = new Subscription();
    this.isAgent = this.userService.getActiveUser().hasRole(UserRoleEnum.agent);
    this.isCustomer = this.userService
      .getActiveUser()
      .hasRole(UserRoleEnum.customer);
    this.isJoining = false;
    this.participantName = this.getStoredDisplayName();
    this.isRequestingName =
      this.isCustomer &&
      config.customerParamEnabled(CustomerParam.DISPLAY_NAME_REQUIRED);
  }

  ngOnInit() {
    // audio stream is opened at openStreamEnumerateDevices, so we need to sync ui here.
    // would be better to have a central redux store to ask for stuff
    if (this.conversationType === ConversationTypeEnum.voiceCall) {
      this.camOn = false;
    }

    this.subscription.add(
      this.mediaService.speakerChanged$.subscribe(() => {
        this.testSpeaker();
      })
    );

    this.subscription.add(
      this.store.updated$.subscribe(() => {
        if (this.stream !== this.store.mystream) {
          this.stream = this.store.mystream;
          this.camOn = this.stream?.video.state === "enabled";
          this.micOn = this.stream?.audio.state === "enabled";
          this.isEffectsOpen &&=
            this.stream &&
            (this.stream.type === StreamTypes.CAM ||
              this.stream.type === StreamTypes.VIDEO);

          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.mediaService.addedDevices$.subscribe((devices) => {
        this.cd.detectChanges();
      })
    );

    this.subscription.add(
      merge(
        this.mediaFilter.filterDisabled$,
        this.mediaFilter.filterError$
      ).subscribe(() => {
        this.isApplyingFilter = false;
        this.cd.detectChanges();
      })
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      // no need to filter the streams, at this point we are alone
      this.voiceDetectionService.volumeLevel$.subscribe((volume) => {
        if (this.volumeElem) {
          this.volumeElem.nativeElement.style.height = volume.level + "px";
        }
      })
    );

    (
      document.querySelector(
        "[data-tid='device-setup/accepts-constraints']"
      ) as HTMLButtonElement
    )?.focus();

    this.keyboard.listen(KeyCodes.Esc, this.onEscKey.bind(this));
  }

  private onEscKey() {
    if (this.isEffectsOpen) {
      this.toggleEffects();
    } else {
      this.cancel();
    }
  }

  @HostBinding("class") get class() {
    return {
      "device-setup-mobile": this.isMobile,
    };
  }

  async tileReady(tile: ITile) {
    // give a moment for dom to change
    await new Promise(requestAnimationFrame);
    if (this.tileRef) {
      const width = this.tileRef.container.clientHeight * tile.ratio;
      this.tileRef.container.style.width = width + "px";
      this.isTileReady = true;
    }
    this.cd.markForCheck();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.keyboard.unlisten(KeyCodes.Esc, this.onEscKey.bind(this));
  }

  /* actions */

  async toggleCam(on: boolean) {
    try {
      this.isTileReady = false;
      this.isTogglingCam = true;
      if (!on) {
        await this.mediaService.mute(StreamTrackKindEnum.video);
      } else {
        await this.mediaService.unmute(StreamTrackKindEnum.video);
      }
    } catch (ex) {
      this.isTileReady = true;
      setTimeout(() => {
        this.camOn = false;
        this.cd.detectChanges();
      }, 100);
      this.notification.error("Could not connect to camera");
    } finally {
      this.isTogglingCam = false;
      this.cd.detectChanges();
    }
  }

  toggleEffects() {
    this.isEffectsOpen = !this.isEffectsOpen;
    this.cd.detectChanges();

    if (this.isEffectsOpen) {
      this.winService
        .onClickedOutside(
          this.effectsMenuRef.nativeElement,
          this.effectsMenuButtonRef.nativeElement
        )
        .then(() => {
          if (this.isEffectsOpen) this.toggleEffects();
        });
    }
  }

  async switchCam() {
    if (this.isSwitching) {
      return;
    }

    this.isSwitching = true;
    this.activity.loading(true, "switching");

    try {
      await this.mediaService.switchCamera();
    } catch (err) {
      this.notification.error("Switch camera failed", {
        body: "Could not use this camera device. Reverted back to the previous one.",
      });

      debugError(err);
    } finally {
      this.isSwitching = false;
      this.activity.hide();
      this.cd.detectChanges();
    }
  }

  toggleDevicesPopup() {
    this.devicesOpen = !this.devicesOpen;
  }

  async testSpeaker() {
    this.notification.playSound(NotificationSoundEnum.speakerTest);
  }

  cancel() {
    this.canceled.emit();
  }

  acceptConstraints(form: FormGroup, event: Event) {
    event.preventDefault();

    if (this.isJoining) {
      return;
    }

    if (!form.valid) {
      markFormFields(form);
      return;
    }

    // update participant name
    if (this.isRequestingName) {
      const details: IUserDetails = this.userService.getUserDetails() || {
        id: this.userService.getActiveUser().getId(),
        name: null,
        displayName: null,
      };
      details.displayName = this.participantName;
      this.userService.setUserDetails(details);
      localStore.setItem(KEY_USER_DISPLAY_NAME, this.participantName);
    }

    this.isJoining = true;
    this.isEffectsOpen = false;
    this.deviceService.isDevicesSetup = true;

    // skip setup for widget as well
    if (this.skipSetup || this.isOriginWidget) {
      // store we have accepted the device constraints, not to show again the device setup
      this.deviceService.keepDeviceSetupUntil(
        this.mediaService.audioDevice?.deviceId,
        this.mediaService.videoDevice?.deviceId,
        new Date().toISOString()
      );
    }

    this.accepted.emit();
  }

  setDefaults() {
    MediaDevices.savePreferred({
      audioinput: this.mediaService.audioDevice?.deviceId,
      videoinput: this.mediaService.videoDevice?.deviceId,
      audiooutput: this.mediaService.speakerDevice?.deviceId,
    });

    this.notification.success("Saved", { body: "Devices set as defaults" });
  }

  /* getters */

  getStoredDisplayName(): string {
    return (
      this.userService.getUserDetails()?.displayName ||
      this.userService.getUserDetails()?.name
    );
  }

  get audioDeviceId() {
    return this.mediaService.audioDevice?.deviceId;
  }

  get videoDeviceId() {
    return this.mediaService.videoDevice?.deviceId;
  }

  get isJoinDisabled() {
    return this.isJoining; // || !this.stream;
  }

  get isMobile() {
    return DeviceService.isMobile;
  }

  get isOriginWidget() {
    return this.room?.isWidget;
  }

  get isFilterAvailable() {
    return this.isVideoDeviceAvailable && this.mediaFilter.supportsMediaFilters;
  }

  get isFilterOn() {
    return this.mediaFilter.isMediaFilterOn || this.isApplyingFilter;
  }

  get isMediaAvailable() {
    return MediaDevices.has.videoinput || this.isAudioAvailable;
  }

  get isVideoDeviceAvailable() {
    return (
      MediaDevices.has.videoinput &&
      this.conversationType !== ConversationTypeEnum.voiceCall &&
      // if we don't have audio we should at least have video to join the call
      this.mediaRules.isVideoControlAvailable
    );
  }

  get isVideoDeviceMissing() {
    return MediaDevices.has.videoinput === 0;
  }

  get isAudioAvailable() {
    return MediaDevices.has.audioinput && this.mediaRules.isAudioAvailable;
  }

  get isAudioDeviceMissing() {
    return MediaDevices.has.audioinput === 0;
  }

  get isAudioVisible() {
    return (
      this.isAudioAvailable &&
      // does not make sense to have mic shown
      this.mediaRules.isVideoAvailable
    );
  }
}
