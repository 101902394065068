import {
  AppointmentChannelEnum,
  CLAIM_OPEN_ID,
  ConversationOriginEnum,
  IApplicationConfig,
  IOpenIDConnectApplicationConfig,
  IPagedResponse,
  IUser,
  IUserDetails,
} from "../../../core-ui";
import { IIntegrationStrategy } from "../../../core-ui/models/strategies";

export class NoIntegrationStrategy implements IIntegrationStrategy {
  installIntegration(integration, origin) {
    return null;
  }

  async getIntegrations() {
    return [];
  }

  getIntegrationId() {
    return null;
  }

  getIntegrationURL() {
    return null;
  }

  deleteIntegration() {
    return null;
  }

  getOauthClientId() {
    return null;
  }

  isInstalled() {
    return true;
  }

  supportsInteractionDestination(destination) {
    return false;
  }

  supportsInteractionOrigin(origin: ConversationOriginEnum): boolean {
    return false;
  }

  supportsAppointmentChannel(channel: AppointmentChannelEnum): boolean {
    return false;
  }

  // we need member roles to promote standalone users to admins/supervisors
  supportsMemberRoles(): boolean {
    return true;
  }

  getIntegrationIdentifier() {
    return null;
  }

  getIntegrationUserId(user: IUser): string {
    return user.getClaim(CLAIM_OPEN_ID);
  }

  getMember(id: string): Promise<IUserDetails> {
    return null;
  }

  getMembers(
    page: number,
    size: number
  ): Promise<IPagedResponse<IUserDetails>> {
    return null;
  }

  hasLicense(key: "freetrial" | "cobrowse" | "video"): boolean {
    return true;
  }

  getExportableConfig(config: IApplicationConfig): IApplicationConfig {
    try {
      const deepCopy = JSON.stringify(config);
      const cf: IOpenIDConnectApplicationConfig = JSON.parse(deepCopy);

      // remove params that are application specific
      delete cf.openidParameters;
      return cf;
    } catch (ex) {
      return config;
    }
  }

  mergeConfigs(
    oldValue: IApplicationConfig,
    newValue: IApplicationConfig
  ): IApplicationConfig {
    return { ...newValue };
  }
}
