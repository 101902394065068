import { PureCloudService } from "../../../services/purecloud.service";

import { TranslateService } from "@ngx-translate/core";
import {
  ConversationChannelEnum,
  ConversationTypeEnum,
  debugError,
  GenericErrorHandler,
  IConversationEventHandlers,
  ITranscript,
  ProtectedTicketService,
} from "../../../../core-ui";
import {
  IInteraction,
  IInteractionDetails,
  IInteractionMessageOptions,
} from "../../../../core-ui/models/IInteraction";
import { IInteractionStrategy } from "../../../../core-ui/models/strategies";
import {
  CALLBACK_NOTIFICATION_TAGS,
  PARAM_CALL_TYPE,
  WIDGET_COBROWSE_REQUEST_PREFIX,
} from "../../../app.enums";
import { GenesysInteraction } from "../../../models";
import { GenesysInteractionDetails } from "../../GenesysInteractionDetails";
import { GenesysInteractionScheduleRequest } from "../../GenesysInteractionScheduleRequest";

export class GenesysCloudInteractionStrategy implements IInteractionStrategy {
  activeInteraction: IInteraction;

  constructor(
    private pureCloudService: PureCloudService,
    private ticketService: ProtectedTicketService,
    private errorHandler: GenericErrorHandler,
    private translate: TranslateService
  ) {}

  isActiveInteractionAvailable(): boolean {
    return !!this.activeInteraction;
  }

  getActiveInteraction(): IInteraction {
    return this.activeInteraction;
  }

  setActiveInteraction(interaction: IInteraction) {
    if (!!interaction) {
      this.pureCloudService.setConversationId(interaction.getId());
    }
    this.activeInteraction = interaction;
  }

  clearActiveInteraction() {
    this.activeInteraction = null;
    this.pureCloudService.removeConversationId();
  }

  async discoverActiveInteraction(): Promise<IInteraction> {
    try {
      const info = await this.pureCloudService.discoverActiveConversation();
      const interaction = new GenesysInteraction(info);
      this.setActiveInteraction(interaction);
      return interaction;
    } catch (ex) {
      // ex.messsage ->
      // no-active-conversation: we have conversations but none has both agent & customer connected
      // no-conversations-available: we don't have any conversations
      // handle if needed
      return null;
    }
  }

  registerConversationEventHandlers(handlers: IConversationEventHandlers) {
    this.pureCloudService.registerConversationEventHandlers(handlers);
  }

  async retrieveInteractionData(
    id: string,
    channel: ConversationChannelEnum
  ): Promise<IInteraction> {
    try {
      const info = await this.pureCloudService.getConversationInfoById(
        id,
        channel
      );
      if (!info) {
        return null;
      }
      const interaction = new GenesysInteraction({
        conversationId: id,
        ...info,
      });
      return interaction;
    } catch (ex) {
      debugError(ex);
      return null;
    }
  }

  prepareInteractionScheduleRequest(
    queueId: string,
    agentId: string,
    name: string,
    phoneNumber: string,
    scheduledAt: string,
    metadata: { [key: string]: string }
  ) {
    return new GenesysInteractionScheduleRequest(
      queueId,
      agentId,
      name,
      phoneNumber,
      scheduledAt,
      metadata
    );
  }

  scheduleInteraction(
    request: GenesysInteractionScheduleRequest
  ): Promise<void> {
    return this.pureCloudService.createCallback(request.getDto());
  }

  private getTranslateMap(options: IInteractionMessageOptions) {
    return CALLBACK_NOTIFICATION_TAGS.reduce((map, item) => {
      switch (item.type) {
        case "date":
          return {
            ...map,
            [item.format]:
              options.scheduleAt?.format(item.format) || item.example,
          };
        case "option":
          return {
            ...map,
            [item.format]: options[item.format] || item.example,
          };
      }
    }, {});
  }

  replaceTokens(source: string, tokens: { [key: string]: string }): string {
    if (!tokens) {
      return source;
    }
    let target: string = this.translate.instant(source, tokens);
    Object.keys(tokens).forEach((key) => {
      target = target.replace(new RegExp(`{{${key}}}`, "g"), tokens[key]);
    });
    return target;
  }

  async createEmailInteraction(
    queueId: string,
    toAddress: string,
    message: string,
    messageHTML?: string,
    subject?: string,
    options?: IInteractionMessageOptions
  ): Promise<void> {
    let translateMap;
    if (options) translateMap = this.getTranslateMap(options);
    return this.pureCloudService.prepareEmail(
      queueId,
      toAddress,
      this.replaceTokens(message, translateMap),
      this.replaceTokens(messageHTML, translateMap),
      this.replaceTokens(subject, translateMap)
    );
  }

  createSMSInteraction(queueId: string, toNumber: string): Promise<void> {
    return this.pureCloudService.prepareSMS(queueId, toNumber);
  }

  invite(
    toConversationType: ConversationTypeEnum,
    interaction: GenesysInteraction,
    ticket: string,
    options?: IInteractionMessageOptions
  ) {
    let message;
    switch (toConversationType) {
      case ConversationTypeEnum.cobrowse:
        message = WIDGET_COBROWSE_REQUEST_PREFIX + ticket;
        break;
      case ConversationTypeEnum.videoCall:
      case ConversationTypeEnum.voiceCall:
      case ConversationTypeEnum.chat:
        let link: URL | string = "";
        const customerLink = this.ticketService.getTicketWithDomain(ticket);
        try {
          link = new URL(customerLink);
          link.searchParams.set(PARAM_CALL_TYPE, interaction.getType());
        } catch (ex) {
          this.errorHandler.handleError(ex.message + " -> " + customerLink);
          link =
            customerLink.indexOf("?") > -1
              ? `${link}&${PARAM_CALL_TYPE}=${interaction.getType()}`
              : `${link}?${PARAM_CALL_TYPE}=${interaction.getType()}`;
        }
        message = link.toString();
        break;
    }

    // for genesys we use webChat to send auvious messages
    this.postMessageToInteraction(interaction, message, {
      ...options,
      type: "notice",
    });
  }

  async postMessageToInteraction(
    interaction: GenesysInteraction,
    message: string,
    options: IInteractionMessageOptions
  ): Promise<void> {
    const type = options?.type || "standard";

    if (type === "sms") {
      const translateMap = this.getTranslateMap(options);

      return this.pureCloudService.sendSMS(
        interaction.getId(),
        this.replaceTokens(message, translateMap)
      );
    }

    switch (interaction.getChannel()) {
      case ConversationChannelEnum.genesysWebMessaging:
      case ConversationChannelEnum.genesysFacebookMessenger:
      case ConversationChannelEnum.genesysMessaging:
      case ConversationChannelEnum.genesysWhatsApp:
        return this.pureCloudService.postMessageToMessaging(
          interaction.getId(),
          interaction.getCommunicationId(),
          message
        );
      default:
        return this.pureCloudService.postMessageToConversation(
          interaction.getId(),
          interaction.getCommunicationId(),
          message,
          type
        );
    }
  }

  async getChatTranscript(
    conversationId: string,
    channel: ConversationChannelEnum
  ): Promise<ITranscript> {
    try {
      const messages = await this.pureCloudService.getConversationMessages(
        conversationId,
        channel
      );
      const script: ITranscript = {
        conversationId,
        instanceId: null,
        recorderId: null,
        chatTranscriptList: [],
      };
      if (messages) {
        script.chatTranscriptList = messages.map((message) => ({
          id: message.id,
          timestamp: message.timestamp.toISOString(),
          transcript: message.text,
          userDisplayName: message.senderName,
          userId: message.senderId,
        }));
      }
      return script;
    } catch (ex) {
      throw ex;
    }
  }

  async getInteractionDetails(
    interactionId: string
  ): Promise<IInteractionDetails> {
    try {
      const details = await this.pureCloudService.getConversation(
        interactionId
      );
      return new GenesysInteractionDetails(details);
    } catch (ex) {
      debugError(ex);
    }
    return null;
  }
}
